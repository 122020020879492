const axios = require('axios');

require('./bootstrap');
require('mmenu-js');
require('./custom-select');
require('./select2');
require('./gallery');
require('slick-carousel');
require('./number-buttons');
require('./init-scripts');
require('jquery-mask-plugin');
require('jquery-validation');
require('./ekko-lightbox');
//require('./inputmask');


//General scripts
$('.special_select').select2();

$('.special_select').on('select2:open', function( event ) {
    $('.select2-container--open .select2-search--dropdown input.select2-search__field').first().prop('placeholder', 'Busca aquí...').focus();
});

/*
$('.special_select').one('select2:open', function(e) {
    $('input.select2-search__field').focus();
});
*/

$('[data-toggle="tooltip"]').tooltip();

//Menu top link
$('#navbar-top div').replaceWith( $( "#navbar-top-content" ));

//Header bg when scroll
window.onscroll = function() {
    if (window.pageYOffset > 100) {
        $('#header').addClass('body-scroll')
    } else {
        $('#header').removeClass('body-scroll')
    }
};
//Gallery
/*
$(document).ready(function () {
    $(".block__pic").imagezoomsl({
        zoomrange: [2, 2],
        cursorshadeborder:'1px solid #ccc',
        statusdivborder:'1px solid #ccc'
    });
});
*/


//Register Form
//Show and hide company fields
$( "input[name=user_type]" ).change(function() {
    var user_type = $('input[name=user_type]:checked').val();

    if(user_type == 'company') {
        //Show company fields and mark them as mandatory
        $('.company-fields').show(300);
        $('#company_document_type_nit').click()
        $('.company_document_nit input').attr('required',true).val('');
        $('input[name=company_name]').attr('required',true).val('');
    }else {
        //Hide company fields and mark them optionals and empty them if you can
        $('.company_document').attr('required',false).val('');
        $('input[name=company_document_type]'). prop("checked", false);
        $('.company-fields').hide(300);
    }
});

//Show and hide NIT and Verification code fields
$( "input[name=company_document_type]" ).change(function() {
    var document_type = $('input[name=company_document_type]:checked').val();

    if(document_type == 1){//CC
        //Show cc fields
        $('.company_document_cc').show(0);
        $('.company_document_cc input').attr('required',true).val('');
        //Hide NIT fields
        $('.company_document_nit').hide(0);
        $('.company_document_nit input').attr('required',false).val('');

    }else {
        //Hide cc fields
        $('.company_document_cc').hide(0);
        $('.company_document_cc input').attr('required',false).val('');
        //Show NIT fields
        $('.company_document_nit').show(0);
        $('.company_document_nit input').attr('required',true).val('');
    }
});

//Address modal
$('#addressInput').on('keyup blur click', function () {
    $('#addressModal').modal('show')
});
function EnteredAddress() {
    var roadType                = $('select#roadType option:checked').val() || '';
    roadType                    = (roadType > 0 ? $('select#roadType option:checked').text() : '');
    var roadNumber              = $('input[name="road_number"]').val();
    var roadLetter              = $('select#roadLetter option:checked').val() || '';
    roadLetter                  = (roadLetter > 0 ? $('select#roadLetter option:checked').text() : '');
    var roadComplement          = $('select#roadComplement option:checked').val() || '';
    roadComplement              = (roadComplement > 0 ? $('select#roadComplement option:checked').text() : '');

    var secondRoadNumber        = $('input[name="second_road_number"]').val();
    var secondRoadLetter        = $('select#secondRoadLetter option:checked').val() || '';
    secondRoadLetter            = (secondRoadLetter > 0 ? $('select#secondRoadLetter option:checked').text() : '');
    var secondRoadComplement    = $('select#secondRoadComplement option:checked').val() || '';
    secondRoadComplement        = (secondRoadComplement > 0 ? $('select#secondRoadComplement option:checked').text() : '');

    var extraRoadNumber        = $('input[name="extra_road_number"]').val();

    var addressComplement        = $('input[name="address_complement"]').val();

    var enteredAddress = roadType + " " + roadNumber + roadLetter + " " + roadComplement + " # " + secondRoadNumber + " " + secondRoadLetter + " " + secondRoadComplement + " - " + extraRoadNumber + " " + addressComplement;

    $('#entered-address').html(enteredAddress);
    $('input[name="address"]').val(enteredAddress);

    $('input[name="address"]').addClass('mui--is-not-empty');
    $('input[name="address"]').removeClass('mui--is-empty');
    validateForm($('input[name="address"]').closest('form'));

    //Values in hidden form
    $('input[name="form_road_type_id"]').val($('select#roadType option:checked').val());
    $('input[name="form_road_number"]').val(roadNumber);
    $('input[name="form_road_letter_id"]').val($('select#roadLetter option:checked').val());
    $('input[name="form_road_complement"]').val($('select#roadComplement option:checked').val());
    $('input[name="form_second_road_number"]').val(secondRoadNumber);
    $('input[name="form_second_road_letter_id"]').val($('select#secondRoadLetter option:checked').val());
    $('input[name="form_second_road_complement_id"]').val( $('select#secondRoadComplement option:checked').val());
    $('input[name="form_extra_road_number"]').val(extraRoadNumber);
    $('input[name="form_address_complement"]').val(addressComplement);

}

//Inicializar el cambio de dirección
$('.address-input input').keyup(function () {
   EnteredAddress();
});
$(document).on('change', '.address-input select', function (e) {
    EnteredAddress();
});

//Masks


$("#mobile-phone").mask('000-000-0000');
$("#land-line").mask('000-0000');

 /*

$("#mobile-phone").inputmask({
    mask: ["999-999-9999"],
    onKeyDown: function () {
        $(this).addClass('mui--is-not-empty').removeClass('mui--is-empty')
    }
});

$("#land-line").inputmask({
    mask: ["999-9999"],
    onKeyDown: function () {
        $(this).addClass('mui--is-not-empty').removeClass('mui--is-empty')
    }
});
*/
function isValidEmailAddress(emailAddress) {
    var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
    return pattern.test(emailAddress);
}

$('#checkUniqueEmail').on('change keyup blur click', function () {

    var form = $(this).closest('form');

    if(isValidEmailAddress($(this).val()))
    {
        axios.get('/api/check-unique-email', {
            params: {
                email: form.find('#checkUniqueEmail').val()
            }
        })
            .then(function (response) {
                $("#uniqueEmailMessage").removeClass('danger').addClass('green-text').html("<i class='fas fa-check-circle'></i> " +  response.data.msg);
            })
            .catch(function (error) {
                $("#uniqueEmailMessage").removeClass('green-text').addClass('danger').html("<i class='fas fa-exclamation-circle'></i> " + error.response.data.msg);
            });
    } else {
        $("#uniqueEmailMessage").removeClass('green-text').addClass('danger').html("<i class='fas fa-exclamation-circle'></i> Email no válido");
    }
});


//Get cities when a state is selected
$(document).on('change', '#states', function (e) {
    var state_id = $(this).val();

    axios.get('/api/get-cities', {
        params: {
            state_id: state_id
        }
    })
        .then(function (response) {
            $('#cities').empty();
            $('#cities').html("<option value=''>Seleccione</option>");
            for (var i = 0; i < response.data.cities.length; i++) {
                $('#cities').append("<option value='"+response.data.cities[i].id+"'>"+response.data.cities[i].city+"</option>")
            }
        })
        .catch(function (error) {
            $('#cities').empty();
            $('#cities').html("<option value=''>Seleccione un departamento</option>");
            console.log(error);
        });
});

//Form Validation
$('input').on('keyup blur click', function () {
    validateForm($(this).closest('form'));
});
$('select').on('change', function () {
    validateForm($(this).closest('form'));
});
$('input[type=radio]').on('change', function () {
    validateForm($(this).closest('form'));
});
$('.btn-number').on('click', function () {
    validateForm($(this).closest('form'));
});
function validateForm(form) {
    if (form.validate().checkForm()) {
        form.find('button[type=submit]').prop('disabled', false)
    } else {
        form.find('button[type=submit]').prop('disabled', true)
    }
}

//Newsletter Form
$("#newsletterForm").submit(function(e){
    e.preventDefault();

    //Change button text
    $("#newsletterForm button[type='submit']").html("Procesando <i class='fas fa-circle-notch fa-spin'></i>");

    axios.post('/api/newsletter/register', {
        name:           $('#newsletterForm input[name=name]').val(),
        last_name:      $('#newsletterForm input[name=last_name]').val(),
        email:          $('#newsletterForm input[name=email]').val()
    })
        .then(function (response) {
            $("#newsletterForm button[type='submit']").html(response.data.msg);
            setTimeout(function(){ $(location).attr('href',response.data.url); }, 2000);
        })
        .catch(function (error) {
            $('#newsletterForm #error-message span').html(error.response.data.msg);
            $("#newsletterForm button[type='submit']").html('Inténtalo de nuevo');
        });
});

//Newsletter Form
$("#newsletterFormModal").submit(function(e){
    e.preventDefault();

    console.log('ya me está dando algo')

    //Change button text
    $("#newsletterFormModal button[type='submit']").html("Procesando <i class='fas fa-circle-notch fa-spin'></i>");

    axios.post('/api/newsletter/register', {
        name:           $('#newsletterFormModal input[name=name]').val(),
        last_name:      $('#newsletterFormModal input[name=last_name]').val(),
        email:          $('#newsletterFormModal input[name=email]').val()
    })
        .then(function (response) {
            $("#newsletterFormModal button[type='submit']").html(response.data.msg);
            setTimeout(function(){ $(location).attr('href',response.data.url); }, 2000);
        })
        .catch(function (error) {
            $('#newsletterFormModal #error-message span').html(error.response.data.msg);
            $("#newsletterFormModal button[type='submit']").html('Inténtalo de nuevo');
        });
});


//Sign In Form in Modal
$("#signInForm").submit(function(e){
    e.preventDefault();

    //Change button text
    $("#signInForm button[type='submit']").html("<i class='fas fa-circle-notch fa-spin'></i> Procesando");

    axios.post('/api/login', {
        email:          $('#signInForm input[name=email]').val(),
        password:       $('#signInForm input[name=clave]').val()
    })
        .then(function (response) {
            $("#signInForm button[type='submit']").html(response.data.msg);
            setTimeout(function(){ location.reload(true); }, 1000);
        })
        .catch(function (error) {
            $('#signInForm #error-message span').html(error.response.data.msg);
            $("#signInForm button[type='submit']").html('Inténtalo de nuevo');
        });
});

//Toggle Sign In and Register Modals
signModal
$('#signModal').on('shown.bs.modal', function (e) {
    $("body").addClass("modal-open")
});
$('#registerModal').on('shown.bs.modal', function (e) {
    $("body").addClass("modal-open")
});

//Register Form in Modal
$("#registerForm").submit(function(e){
    e.preventDefault();

    var form = document.getElementById('registerForm');
    var data = new FormData(form);

    //Change button text
    $("#registerForm button[type='submit']").html("<i class='fas fa-circle-notch fa-spin'></i> Procesando");

    axios.post('/api/register', data)
        .then(function (response) {
            $("#registerForm button[type='submit']").html(response.data.msg);
            setTimeout(function(){ location.reload(true); }, 1000);
        })
        .catch(function (error) {
            $('#registerForm #error-message span').html(error.response.data.msg);
            $("#registerForm button[type='submit']").html('Inténtalo de nuevo');
        });
});


//Header buttons
$(".header-dropdown").hover(function(){
    var button          = $(this).children('.header-button')
    var dropdownMenu    = $(this).children(".dropdown-menu");
    if(dropdownMenu.is(":visible")){
        button.toggleClass("open");
    }
});



//Add To Cart Form
$("#addToCartForm").submit(function(e){
    e.preventDefault();

    //Change button text
    $("#addToCartForm button[type='submit']").html("<i class='fas fa-circle-notch fa-spin'></i> Procesando");

    axios.post('/api/add-to-cart', {
        product_id: $('#addToCartForm input[name=product_id]').val(),
        color_id:   $("#addToCartForm input[name='color_id']:checked"). val(),
        ink_id:     $("#addToCartForm input[name='ink_id']:checked"). val(),
        quantity:   $('#addToCartForm input[name=quantity]').val()
    })
        .then(function (response) {
            //Activate Add to Cart Confirmation Modal
            $('#AddToCartConfirmationModal').modal('show');
            $('#AddToCartConfirmationModal').on('shown.bs.modal', function (e) {
                $("body").addClass("modal-open")
            });

            //Change number in cart in header
            $('#products-in-cart').html( response.data.cartCount )
            $('#mobile-products-in-cart').html( response.data.cartCount )

            //Change the button text
            $("#addToCartForm button[type='submit']").html(response.data.msg);

        })
        .catch(function (error) {
            $('#addToCartForm #error-message span').html(error.response.data.msg);
            $("#addToCartForm button[type='submit']").html('Inténtalo de nuevo');
        });
});

//Update Cart Item Form
$(".submitOnChange").change(function(e){
    e.preventDefault();

    form = $(this).closest('form');

    var detailId = form.find('input[name=detail_id]').val();

    //Inform that the update is comming
    form.find('.updated-cart-item').html( " <i class='fas fa-circle-notch fa-spin'></i>" );

    axios.post('/api/update-cart-detail', {
        detail_id: form.find('input[name=detail_id]').val(),
        quant:     form.find('input[name="quant['+ detailId + ']"]').val()
    })
        .then(function (response) {

            form.find('.updated-cart-item').html( " <i class='fas fa-check-circle green-text'></i>" );

        })
        .catch(function (error) {
            $('#addToCartForm #error-message span').html(error.response.data.msg);
            $("#addToCartForm button[type='submit']").html('Inténtalo de nuevo');
        });
});


//Delete Cart Item Form
$(".deleteCartItemForm").submit(function(e){
    e.preventDefault();
    //Inform that the update is comming
    $(this).find('button span').html( " <i class='fas fa-circle-notch fa-spin'></i> Eliminar" );

    var form = $(this);

    axios.post('/api/delete-cart-detail', {
        detail_id: $(this).find('input[name=detail_id]').val()
    })
        .then(function (response) {

            //Change item row background color
            form.closest('.cart-item-row').addClass('deleted-row');

            //Change number in cart in header
            $('#products-in-cart').html( response.data.cartCount )
            $('#mobile-products-in-cart').html( response.data.cartCount )
            $('#cart-count-title').html( response.data.cartCount )

            //Hide item row
            setTimeout(function(){ form.closest('.cart-item-row').hide(300) }, 300);

            //If that one was the last one, display a message
            setTimeout(function(){ location.reload(true); }, 50);
        })
        .catch(function (error) {
            $('#error-message span').html(error.response.data.msg);
        });
});


//Register and Quote Form
$("#registerAndQuote").submit(function(e){
    e.preventDefault();

    var form = document.getElementById('registerAndQuote');
    var data = new FormData(form);

    //Change button text
    $("#registerAndQuote button[type='submit']").html("<i class='fas fa-circle-notch fa-spin'></i> Procesando");

    axios.post('/checkout/register', data)
        .then(function (response) {

            $("#registerAndQuote button[type='submit']").html(response.data.msg);
            setTimeout(function(){ $(location).attr('href', '/cotizacion-creada/' + response.data.websiteQuoteId); }, 500);
        })
        .catch(function (error) {
            $('#error-message span').html(error.response.data.msg);
        });
});


//Create Client and Quote Form
$("#createClientAndQuote").submit(function(e){
    e.preventDefault();

    var form = document.getElementById('createClientAndQuote');
    var data = new FormData(form);

    //Change button text
    $("#createClientAndQuote button[type='submit']").html("<i class='fas fa-circle-notch fa-spin'></i> Procesando");

    axios.post('/checkout/user', data)
        .then(function (response) {

            $("#createClientAndQuote button[type='submit']").html(response.data.msg);
            setTimeout(function(){ $(location).attr('href', '/cotizacion-creada/' + response.data.websiteQuoteId); }, 1000);
        })
        .catch(function (error) {
            $('#error-message span').html(error.response.data.msg);
        });
});


//Submit Quote Form
$("#submitQuote").submit(function(e){
    e.preventDefault();

    var form = document.getElementById('submitQuote');
    var data = new FormData(form);

    //Change button text
    $("#submitQuote button[type='submit']").html("<i class='fas fa-circle-notch fa-spin'></i> Procesando");

    axios.post('/checkout/client', data)
        .then(function (response) {

            $("#submitQuote button[type='submit']").html(response.data.msg);
            setTimeout(function(){ $(location).attr('href', '/cotizacion-creada/' + response.data.websiteQuoteId); }, 1500);
        })
        .catch(function (error) {
            $('#error-message span').html(error.response.data.msg);
        });
});





//Create Profile Form
$("#createProfile").submit(function(e){
    e.preventDefault();

    var form = document.getElementById('createProfile');
    var data = new FormData(form);

    //Change button text
    $("#createProfile button[type='submit']").html("<i class='fas fa-circle-notch fa-spin'></i> Procesando");

    axios.post('/micuenta/crear-perfil', data)
        .then(function (response) {

            $("#createProfile button[type='submit']").html("<i class='fas fa-check-circle'></i> " +  response.data.msg);
            setTimeout(function(){ location.reload(true); }, 2000);
        })
        .catch(function (error) {
            $('#error-message span').html(error.response.data.msg);
        });
});



//Update Profile Form
$("#updateProfile").submit(function(e){
    e.preventDefault();

    var form = document.getElementById('updateProfile');
    var data = new FormData(form);

    //Change button text
    $("#updateProfile button[type='submit']").html("<i class='fas fa-circle-notch fa-spin'></i> Procesando");

    axios.post('/micuenta/actualizar-perfil', data)
        .then(function (response) {

            $("#updateProfile button[type='submit']").html("<i class='fas fa-check-circle'></i> " +  response.data.msg);
            setTimeout(function(){ location.reload(true); }, 2000);
        })
        .catch(function (error) {
            $('#error-message span').html(error.response.data.msg);
        });
});



//Load Ad
if ($("#loadAd").is(function() {

        //var form = document.getElementById('loadAd');
        var section = $('#loadAd input[name=section]').val();
        var id      = $('#loadAd input[name=id]').val();


        $('#firePopup > div').load( '/ads/load?section=' + section + '&id=' + id );

        setTimeout(function(){
            $('#popupModal').modal('show');
            $('#popupModal').on('shown.bs.modal', function (e) {
                $("body").addClass("modal-open")
            });
        }, 1000);

}));

$('#mobile-search-text').on('click', function (e) {

    $(this).css('transform', 'none').css('background', 'white').css('width', '200px');
    $('#mobile-search-close').css('visibility', 'visible');
    $('#mobile-search-button').addClass('btn-default');

    $('header .brand').css('background', 'white');
    $('#mobile-search-form').css('width', '100%').css('right', '2px');
    $('span.products-in-cart').css('display', 'none');
    $('.brand .logo').css('visibility', 'hidden');
    $('.brand .logo-white').css('visibility', 'hidden');
    $('#mobile-menu-link').css('visibility', 'hidden');
    $('.mobile-cart-icon').css('visibility', 'hidden');
    $('.mobile-user-icon').css('visibility', 'hidden');

    $('#mobile-search-text').focus();

});

$('#mobile-search-close').on('click', function (e) {

    console.log('close clicked');

    $('#mobile-search-text').css('transform', 'translateX(29px)').css('background', 'transparent').css('width', '20px');
    $('#mobile-search-button').removeClass('btn-default');

    $('header .brand').css('background', 'none');
    $('#mobile-search-form').css('width', 'auto').css('right', '60px');
    $('span.products-in-cart').css('display', 'inline-block');
    $('.brand .logo').css('visibility', 'visible');
    $('.brand .logo-white').css('visibility', 'visible');
    $('#mobile-menu-link').css('visibility', 'visible');
    $('.mobile-cart-icon').css('visibility', 'visible');
    $('.mobile-user-icon').css('visibility', 'visible');

    $(this).css('visibility', 'hidden');

});


$('.mouseOverTrigger').on('mouseover', function () {
    $(this).trigger("click");
});
