/*Dropdown Menu*/
$('.dropdown-select').click(function () {
    $(this).attr('tabindex', 1).focus();
    $(this).toggleClass('active');
    $(this).find('.dropdown-menu').slideToggle(300);
});
$('.dropdown-select').focusout(function () {
    $(this).removeClass('active');
    $(this).find('.dropdown-menu').slideUp(300);
});
$('.dropdown-select .dropdown-menu li').click(function () {
    $(this).parents('.dropdown-select').find('span').text($(this).text());
    $(this).parents('.dropdown-select').find('input').attr('value', $(this).attr('id'));
});
/*End Dropdown Menu*/