//Carousels
$('#categories-home-carousel').slick({
    infinite: true,
    slidesToShow: 7,
    slidesToScroll: 7,
    autoplay: false,
    autoplaySpeed: 2000,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 5,
                slidesToScroll: 5
            }
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3
            }
        }
    ]
});


$('.products-carousel').slick({
    lazyLoad: 'ondemand',
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 4,
    autoplay: false,
    autoplaySpeed: 2000,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3
            }
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                arrows: false,
                dots: true
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                arrows: false,
                dots: true
            }
        }
    ]
});

$('.subcategory-products-carousel').slick({
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 5,
    autoplay: false,
    autoplaySpeed: 2000,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3
            }
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                arrows: false,
                dots: true
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                arrows: false,
                dots: true
            }
        }
    ]
});

$('.quotes-list-gallery').slick({
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 2,
    autoplay: false,
    autoplaySpeed: 2000,
    dots: true,
    arrows: false,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2
            }
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: false,
                dots: false
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: false,
                dots: false
            }
        }
    ]
});


$('.product-vertical-slider').slick({
    dots: true,
    infinite: false,
    speed: 300,
    slidesToShow: 4,
    slidesToScroll: 1,
    vertical: true,
    verticalSwiping: true,
    dots: false,
    centerPadding: '50px',
    arrows: true,
    prevArrow: '<button type="button" data-role="none" class="slick-prev" aria-label="Previous" tabindex="0" role="button">Previous</button>',
    nextArrow: '<button type="button" data-role="none" class="slick-next" aria-label="Next" tabindex="0" role="button">Next</button>',
    responsive: [{
        breakpoint: 1024,
        settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            infinite: true,
        }
    }, {
        breakpoint: 639,
        settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            vertical: false,
            verticalSwiping: false,
        }
    }
    ]
});


//Menu
new Mmenu(
    document.querySelector('#menu'),
    {
        extensions	: [ 'fx-menu-slide', 'theme-white', 'position-front', 'pagedim-black' ],
        setSelected	: false,
        counters	: true,

        navbars		: [
            {
                position	: 'top',
                content		: [ '<div id="navbar-top"><div></div></div>' ]
            },
            {
                content		: [ 'prev', 'breadcrumbs', 'close' ]
            },
        ],
        navbar: {
            title: "Búsqueda por categoría"
        },
        onClick : {
            close	: true
        }
    }, {
        searchfield : {
            clear 		: false
        },
        navbars		: {
            breadcrumbs	: {
                removeFirst	: false
            }
        }
    }

);

new Mmenu(
    document.querySelector('#mobile-menu'),
    {
        extensions	: [ 'fx-menu-slide', 'theme-white', 'position-front', 'pagedim-black' ],
        setSelected	: false,
        counters	: true,

        navbars		: [
            {
                position	: 'top',
                content		: [ '<div id="navbar-top"><div></div></div>' ]
            },
            {
                content		: [ 'prev', 'breadcrumbs', 'close' ]
            },
        ],
        navbar: {
            title: "Encuentra el souvenir ideal"
        },
        onClick : {
            close	: true
        }
    }, {
        searchfield : {
            clear 		: false
        },
        navbars		: {
            breadcrumbs	: {
                removeFirst	: false
            }
        }
    }
);


//Galeries
$(document).on('click', '[data-toggle="lightbox"]', function(event) {
    event.preventDefault();
    $(this).ekkoLightbox();
});