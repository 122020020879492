


    $.fn.imagezoomsl = function (options) {
        options = options || {};
        return this.each(function () { //return jQuery obj
            if (!$(this).is("img")) return true;
            var that = this;
            setTimeout(function () {
                $(new Image()).on('load', function () {
                    sergelandimagezoomer.init($(that), options);
                }).attr('src', $(that).attr('src'));
            }, 30);
        });

    };

    var sergelandimagezoomer = {};
    $.extend(sergelandimagezoomer, {

        dsetting: { //default settings

            // загрузка big картинки
            loadinggif: '', // картинка показываемая при загрузке big изображения
            loadopacity: 0.1, // прозрачность фона перекрытия tmb картинки при загрузке	big изображения
            loadbackground: '#878787', // цвет фона перекрытия tmb картинки при загрузке	 big изображения


            // лупа (cursorshade)
            cursorshade: true, // показать контейнер лупы
            magnifycursor: 'crosshair', // вид курсора мыши над tmb в формате CSS
            cursorshadecolor: '#fff', // цвет контейнера лупы в формате CSS
            cursorshadeopacity: 0.3, // прозрачность контейнера лупы
            cursorshadeborder: '1px solid black', // внешний бордюра контейнера лупы в формате CSS
            zindex: '', // z-index контейнера лупы
            stepzoom: 0.5, // шаг зуммирования при прокрутке колеса мыши
            zoomrange: [2, 2], // диапазон зуммирования
            zoomstart: 2, // стартовая установка зуммирования
            disablewheel: true, // отключить прокрутку документа колесом мыши когда курсор над картинкой tmb в случае если не задан диапазон зуммирования


            // подсказка (statusdiv)
            showstatus: true, // показывать при наведении на tmb help контейнер
            showstatustime: 2000, // время показа help контейнера
            statusdivborder: '1px solid black',
            statusdivbackground: '#C0C0C0',
            statusdivpadding: '4px',
            statusdivfont: 'bold 13px Arial',
            statusdivopacity: 0.8,


            // контейнер big картинки (magnifier)
            magnifierpos: 'right', // сторона отображения контейнера left/right
            magnifiersize: [0, 0], // размер контейнера
            magnifiereffectanimate: 'showIn', // эффект появления/скрытия fadeIn/showIn/slideIn
            innerzoom: false, // показать контейнер внутри tmb
            innerzoommagnifier: false, // показать контейнер как лупу
            descarea: false, // показать контейнер в произвольной области, область descarea должна иметь width и height
            leftoffset: 15, // отступ слева от tmb картинки
            rightoffset: 15, // отступ справа от tmb картинки
            switchsides: true, // учитывать край экрана
            magnifierborder: '1px solid black', // внешний бордюр


            // контейнер текста снизу к картинке
            textdnbackground: '#fff',
            textdnpadding: '10px',
            textdnfont: '13px/20px cursive',


            // коэффициенты скорости анимации
            scrollspeedanimate: 5 /*4*/ , // прокрутки big картинки
            zoomspeedanimate: 7, // зуммирования (плавность)
            loopspeedanimate: 2.5 /*2.45342*/ , // перемещения области лупы и big контейнера в режиме лупы
            magnifierspeedanimate: 350, // показа big контейнера


            // внешний вид
            classmagnifier: "magnifier",
            classcursorshade: "cursorshade",
            classstatusdiv: "statusdiv",
            classtextdn: "textdn",
            classtracker: "tracker"

        },

        isie: (function () {
            var nAgt = navigator.userAgent;
            if (nAgt.indexOf("MSIE") != -1) return true;
            else return false;
        })(),

        highestzindex: function ($img) {

            var z = 0,
                $els = $img.parents().add($img),
                elz;
            $els.each(function () {
                elz = $(this).css('zIndex');
                elz = isNaN(elz) ? 0 : +elz;
                z = Math.max(z, elz);
            });
            return z;
        },

        getboundary: function (b, val, specs) {
            if (b == "left") {
                var rb = -specs.img.w * specs.newpower + specs.magnifier.w;
                return (val > 0) ? 0 : (val < rb) ? rb : val;
            } else {
                var tb = -specs.img.h * specs.newpower + specs.magnifier.h;
                return (val > 0) ? 0 : (val < tb) ? tb : val;
            }
        },

        controlLoop: function ($tracker) {
            var self = this,
                specs = $tracker.data('specs');
            if (!specs) return;

            var coords = specs.$img.offsetsl(),
                pageX = self.cld.pageX999 - coords.left,
                pageY = self.cld.pageY999 - coords.top;

            self.cld.destU += (self.cld.pageX999 - self.cld.destU) / 2.45342;
            self.cld.destV += (self.cld.pageY999 - self.cld.destV) / 2.45342;

            specs.$statusdiv.css({
                left: self.cld.destU - 10,
                top: self.cld.destV + 20
            });

            var csw = Math.round(specs.magnifier.w / specs.newpower),
                csh = Math.round(specs.magnifier.h / specs.newpower);

            self.cld.destK += (pageX - self.cld.destK) / specs.setting.loopspeedanimate;
            self.cld.destL += (pageY - self.cld.destL) / specs.setting.loopspeedanimate;

            specs.$cursorshade.css({
                left: specs.img.w > csw ? Math.min(specs.img.w - csw, Math.max(0, self.cld.destK - csw / 2)) + coords.left - specs.cursorshade999.border999.left999 : coords.left - specs.cursorshade999.border999.left999,
                top: specs.img.h > csh ? Math.min(specs.img.h - csh, Math.max(0, self.cld.destL - csh / 2)) + coords.top - specs.cursorshade999.border999.top999 : coords.top - specs.cursorshade999.border999.top999
            });

            if (specs.setting.innerzoommagnifier) {

                self.cld.destM += (self.cld.pageX999 - self.cld.destM) / specs.setting.loopspeedanimate;
                self.cld.destN += (self.cld.pageY999 - self.cld.destN) / specs.setting.loopspeedanimate;

                specs.$magnifier.css({
                    left: self.cld.destM - Math.round(specs.magnifier.w / 2),
                    top: self.cld.destN - Math.round(specs.magnifier.h / 2)
                });
                specs.$textdn.css({
                    left: self.cld.destM - Math.round(specs.magnifier.w / 2),
                    top: self.cld.destN + specs.magnifier.h / 2
                });
            }

            self.cld.currU += (pageX - self.cld.currU) / specs.setting.scrollspeedanimate;
            self.cld.currV += (pageY - self.cld.currV) / specs.setting.scrollspeedanimate;

            var newx = -self.cld.currU * specs.newpower + specs.magnifier.w / 2;
            var newy = -self.cld.currV * specs.newpower + specs.magnifier.h / 2;

            specs.$bigimage.css({
                left: self.getboundary('left', newx, specs),
                top: self.getboundary('top', newy, specs)
            });
            self.cld.controlTimer = setTimeout(function () {
                self.controlLoop($tracker);
            }, 30);

        },

        controlLoop2: function ($tracker) {

            var self = this,
                specs = $tracker.data('specs');
            if (!specs) return;

            specs.currM += (specs.newpower - specs.currM) / specs.setting.zoomspeedanimate;
            specs.currM = Math.round(specs.currM * 1000) / 1000;

            specs.$cursorshade.css({
                width: specs.img.w > Math.round(specs.magnifier.w / specs.currM) ? Math.round(specs.magnifier.w / specs.currM) : specs.img.w,
                height: specs.img.h > Math.round(specs.magnifier.h / specs.currM) ? Math.round(specs.magnifier.h / specs.currM) : specs.img.h
            });
            specs.$bigimage.css({
                width: Math.round(specs.currM * specs.bigimage.w * (specs.img.w / specs.bigimage.w)),
                height: Math.round(specs.currM * specs.bigimage.h * (specs.img.h / specs.bigimage.h))
            });

            self.cld.controlTimer2 = setTimeout(function () {
                self.controlLoop2($tracker);
            }, 30);

        },

        cld: {},

        showimage: function ($tracker) {

            var self = this,
                specs = $tracker.data('specs'),
                width = specs.setting.magnifiersize[0],
                height = specs.setting.magnifiersize[1],
                magcoords = {},
                coords = specs.$img.offsetsl(),
                func = function () {},
                left1 = 0,
                top1 = 0;

            magcoords.left = coords.left + (specs.setting.magnifierpos === 'left' ? -specs.magnifier.w - specs.setting.leftoffset : specs.img.w + specs.setting.rightoffset);
            if (specs.setting.switchsides && !specs.setting.innerzoom) {

                if (specs.setting.magnifierpos !== 'left' && magcoords.left + specs.magnifier.w + specs.setting.leftoffset >= $(window).width() && coords.left - specs.magnifier.w >= specs.setting.leftoffset)
                    magcoords.left = coords.left - specs.magnifier.w - specs.setting.leftoffset;
                else if (specs.setting.magnifierpos === 'left' && magcoords.left < 0)
                    magcoords.left = coords.left + specs.img.w + specs.setting.rightoffset;
            }

            left1 = magcoords.left;
            top1 = coords.top;
            specs.$magnifier.css({
                visibility: "visible",
                display: "none"
            });

            if (specs.setting.descarea) {
                left1 = $(specs.setting.descarea).offsetsl().left;
                top1 = $(specs.setting.descarea).offsetsl().top;
            }
            if (specs.setting.innerzoommagnifier) {
                left1 = self.cld.pageX999 - Math.round(specs.magnifier.w / 2);
                top1 = self.cld.pageY999 - Math.round(specs.magnifier.h / 2);
            }

            //*
            func = function () {

                specs.$textdn.stop(true, true).fadeIn(specs.setting.magnifierspeedanimate);
                if (!specs.setting.innerzoommagnifier)
                    specs.$textdn.css({
                        left: left1,
                        top: top1 + height
                    });
            } // */

            if (specs.setting.innerzoom) {

                left1 = coords.left;
                top1 = coords.top;

                func = function () {
                    specs.$img.css({
                        visibility: "hidden"
                    });
                    specs.$textdn.css({
                        left: left1,
                        top: top1 + height
                    }).stop(true, true).fadeIn(specs.setting.magnifierspeedanimate);
                };
            }

            switch (specs.setting.magnifiereffectanimate) {
                case 'slideIn':
                    specs.$magnifier.css({
                        left: left1,
                        top: top1 - height / 3,
                        width: width,
                        height: height
                    })
                        .stop(true, true).show()
                        .animate({
                            top: top1
                        }, specs.setting.magnifierspeedanimate, "easeOutBounceSL", func);
                    break;
                case 'showIn':
                    specs.$magnifier.css({
                        left: coords.left + Math.round(specs.img.w / 2),
                        top: coords.top + Math.round(specs.img.h / 2),
                        width: Math.round(specs.magnifier.w / 5),
                        height: Math.round(specs.magnifier.h / 5)
                    })
                        .stop(true, true).show().css({
                        opacity: "0.1"
                    })
                        .animate({
                            left: left1,
                            top: top1,
                            opacity: "1",
                            width: width,
                            height: height

                        }, specs.setting.magnifierspeedanimate, func);
                    break;
                default:
                    specs.$magnifier.css({
                        left: left1,
                        top: top1,
                        width: width,
                        height: height
                    })
                        .stop(true, true)
                        .fadeIn(specs.setting.magnifierspeedanimate, func);
            }
            if (specs.setting.showstatus && (specs.title999 || specs.help))
                specs.$statusdiv.html(specs.title999 + '<div style="font-size:80%">' + specs.help + '</div>')
                    .stop(true, true)
                    .fadeIn().delay(specs.setting.showstatustime).fadeOut("slow");
            else specs.$statusdiv.hide();
        },

        hideimage: function ($tracker) {

            var self = this,
                specs = $tracker.data('specs'),
                coords = specs.$img.offsetsl();

            switch (specs.setting.magnifiereffectanimate) {

                case 'showIn':
                    specs.$magnifier.stop(true, true)
                        .animate({
                                left: coords.left + Math.round(specs.img.w / 2),
                                top: coords.top + Math.round(specs.img.h / 2),
                                opacity: "0.1",
                                width: Math.round(specs.magnifier.w / 5),
                                height: Math.round(specs.magnifier.h / 5)
                            },
                            specs.setting.magnifierspeedanimate,
                            function () {
                                specs.$magnifier.hide();
                            });
                    break;

                default:
                    specs.$magnifier.stop(true, true).fadeOut(specs.setting.magnifierspeedanimate);
            }

        },

        /* Init function start.  */
        init: function ($img, options, gallery) {

            var setting = $.extend({}, this.dsetting, options),
                basezindex = setting.zindex || this.highestzindex($img),
                img = {
                    w: $img.width(),
                    h: $img.height()
                },
                cld = new cld(),
                title = $img.attr("data-title") ? $img.attr("data-title") : '',
                help = $img.attr("data-help") ? $img.attr("data-help") : '',
                textdn = $img.attr("data-text-bottom") ? $img.attr("data-text-bottom") : '',
                self = this,
                newpower,
                key,
                $magnifier,
                $cursorshade,
                $statusdiv,
                $tracker,
                $textdn;

            if (img.h === 0 || img.w === 0) {
                $(new Image()).on('load', function () {
                    self.init($img, options);
                }).attr("src", $img.attr("src"));
                return;
            }

            $img.css({
                visibility: "visible"
            });
            setting.largeimage = $img.attr("data-large") || $img.attr("src");

            for (key in setting)
                if (setting[key] === '') setting[key] = this.dsetting[key];

            if (setting.zoomrange[0] < setting.zoomstart)
                newpower = setting.zoomstart;
            else newpower = setting.zoomrange[0];

            if (setting.magnifiersize.toString() === '0,0' ||
                setting.magnifiersize.toString() === '')
                if (setting.innerzoommagnifier)
                    setting.magnifiersize = [img.w / 2, img.h / 2];
                else setting.magnifiersize = [img.w, img.h];

            if (setting.descarea && $(setting.descarea).length) {
                if ($(setting.descarea).width() === 0 || $(setting.descarea).height() === 0)
                    setting.descarea = false;
                else setting.magnifiersize = [$(setting.descarea).width(), $(setting.descarea).height()];
            } else setting.descarea = false;

            if (setting.innerzoom) {
                setting.magnifiersize = [img.w, img.h];
                if (!options.cursorshade)
                    setting.cursorshade = false;
                if (!options.scrollspeedanimate)
                    setting.scrollspeedanimate = 10;
            }

            if (setting.innerzoommagnifier) {
                if (!options.magnifycursor)
                    if (window.chrome || window.sidebar)
                        setting.magnifycursor = "none";
                setting.cursorshade = false;
                setting.magnifiereffectanimate = "fadeIn";
            }

            function cld() {
                this.pageX999 = 0;
                this.pageY999 = 0;
            }

            function getspecs($bigimage) {

                $tracker.data("specs", {

                    setting: setting,
                    title999: title,
                    help: help,

                    $img: $img,
                    $magnifier: $magnifier,
                    $bigimage: $bigimage,
                    $statusdiv: $statusdiv,
                    $cursorshade: $cursorshade,
                    $textdn: $textdn,

                    img: img,
                    bigimage: {
                        w: $bigimage.width(),
                        h: $bigimage.height()
                    },
                    magnifier: {
                        w: $magnifier.width(),
                        h: $magnifier.height()
                    },
                    cursorshade999: {
                        w: $cursorshade.width(),
                        h: $cursorshade.height(),
                        border999: {
                            left999: parseInt($cursorshade.css("border-left-width")) || 0,
                            top999: parseInt($cursorshade.css("border-top-width")) || 0
                        }
                    },

                    currM: newpower,
                    newpower: newpower
                });

            }

            function isImageLoaded(img) {
                if (!img.complete)
                    return false;
                if (typeof img.naturalWidth !== "undefined" && img.naturalWidth === 0)
                    return false;
                return true;
            }

            var toFix = ['wheel', 'mousewheel', 'DOMMouseScroll', 'MozMousePixelScroll'];
            var toBind = 'onwheel' in document || document.documentMode >= 9 ? ['wheel'] : ['mousewheel', 'DomMouseScroll', 'MozMousePixelScroll'];
            var lowestDelta, lowestDeltaXY;

            if ($.event.fixHooks) {
                for (var i = toFix.length; i;) {
                    $.event.fixHooks[toFix[--i]] = $.event.mouseHooks;
                }
            }

            $.event.special.mousewheel = {
                setup: function () {
                    if (this.addEventListener) {
                        for (var i = toBind.length; i;) {
                            this.addEventListener(toBind[--i], handler, false);
                        }
                    } else {
                        this.onmousewheel = handler;
                    }
                },

                teardown: function () {
                    if (this.removeEventListener) {
                        for (var i = toBind.length; i;) {
                            this.removeEventListener(toBind[--i], handler, false);
                        }
                    } else {
                        this.onmousewheel = null;
                    }
                }
            };

            function handler(event) {
                var orgEvent = event || window.event,
                    args = [].slice.call(arguments, 1),
                    delta = 0,
                    deltaX = 0,
                    deltaY = 0,
                    absDelta = 0,
                    absDeltaXY = 0,
                    fn;
                event = $.event.fix(orgEvent);
                event.type = "mousewheel";

                // Old school scrollwheel delta
                if (orgEvent.wheelDelta) {
                    delta = orgEvent.wheelDelta;
                }
                if (orgEvent.detail) {
                    delta = orgEvent.detail * -1;
                }

                // New school wheel delta (wheel event)
                if (orgEvent.deltaY) {
                    deltaY = orgEvent.deltaY * -1;
                    delta = deltaY;
                }
                if (orgEvent.deltaX) {
                    deltaX = orgEvent.deltaX;
                    delta = deltaX * -1;
                }

                // Webkit
                if (orgEvent.wheelDeltaY !== undefined) {
                    deltaY = orgEvent.wheelDeltaY;
                }
                if (orgEvent.wheelDeltaX !== undefined) {
                    deltaX = orgEvent.wheelDeltaX * -1;
                }

                // Look for lowest delta to normalize the delta values
                absDelta = Math.abs(delta);
                if (!lowestDelta || absDelta < lowestDelta) {
                    lowestDelta = absDelta;
                }
                absDeltaXY = Math.max(Math.abs(deltaY), Math.abs(deltaX));
                if (!lowestDeltaXY || absDeltaXY < lowestDeltaXY) {
                    lowestDeltaXY = absDeltaXY;
                }

                // Get a whole value for the deltas
                fn = delta > 0 ? "floor" : "ceil";
                delta = Math[fn](delta / lowestDelta);
                deltaX = Math[fn](deltaX / lowestDeltaXY);
                deltaY = Math[fn](deltaY / lowestDeltaXY);

                // Add event and delta to the front of the arguments
                args.unshift(event, delta, deltaX, deltaY);

                return ($.event.dispatch || $.event.handle).apply(this, args);
            }

            $.fn.offsetsl = function () {
                var elem = this.get(0);

                function getOffsetSum(elem) {
                    var top999 = 0,
                        left999 = 0;
                    while (elem) {
                        top999 = top999 + parseInt(elem.offsetTop);
                        left999 = left999 + parseInt(elem.offsetLeft);
                        elem = elem.offsetParent;
                    }
                    return {
                        top: top999,
                        left: left999
                    }
                }
                if (elem.getBoundingClientRect)
                    return this.offset();
                else return getOffsetSum(elem)
            }

            $.easing.easeOutBounceSL = function (x, t, b, c, d) {
                if ((t /= d) < (1 / 2.75)) {
                    return c * (7.5625 * t * t) + b;
                } else if (t < (2 / 2.75)) {
                    return c * (7.5625 * (t -= (1.5 / 2.75)) * t + .75) + b;
                } else if (t < (2.5 / 2.75)) {
                    return c * (7.5625 * (t -= (2.25 / 2.75)) * t + .9375) + b;
                } else {
                    return c * (7.5625 * (t -= (2.625 / 2.75)) * t + .984375) + b;
                }
            }

            $magnifier = $("<div />")
                .attr({
                    "class": setting.classmagnifier
                })
                .css({
                    position: "absolute",
                    zIndex: basezindex,
                    width: setting.magnifiersize[0],
                    height: setting.magnifiersize[1],
                    left: -10000,
                    top: -10000,
                    visibility: "hidden",
                    overflow: "hidden"
                })
                .appendTo(document.body);

            if (!options.classmagnifier)
                $magnifier.css({
                    border: setting.magnifierborder
                });

            $cursorshade = $("<div />");
            if (setting.cursorshade) {
                $cursorshade.attr({
                    "class": setting.classcursorshade
                })
                    .css({
                        zIndex: basezindex,
                        display: "none",
                        position: "absolute",
                        width: Math.round(setting.magnifiersize[0] / setting.zoomstart),
                        height: Math.round(setting.magnifiersize[1] / setting.zoomstart),
                        top: 0,
                        left: 0
                    })
                    .appendTo(document.body);

                if (!options.classcursorshade)
                    $cursorshade.css({
                        border: setting.cursorshadeborder,
                        opacity: setting.cursorshadeopacity,
                        backgroundColor: setting.cursorshadecolor
                    });
            }

            if (!setting.loadinggif)
                setting.loadinggif = "";

            $statusdiv = $("<div />")
                .attr({
                    "class": setting.classstatusdiv + " preloadevt"
                })
                .css({
                    position: "absolute",
                    display: "none",
                    zIndex: basezindex,
                    top: 0,
                    left: 0
                })
                .html('<img src="' + setting.loadinggif + '" />')
                .appendTo(document.body);

            $tracker = $("<div />")
                .attr({
                    "class": setting.classtracker
                })
                .css({
                    zIndex: basezindex,
                    backgroundImage: self.isie ? "url(cannotbe)" : "none",
                    position: "absolute",
                    width: img.w,
                    height: img.h,
                    left: gallery ? $img.offsetsl().left : -10000,
                    top: gallery ? $img.offsetsl().top : -10000
                }).appendTo(document.body);

            $textdn = $("<div />");
            if (textdn) {
                $textdn.attr({
                    "class": setting.classtextdn
                })
                    .css({
                        position: "absolute",
                        zIndex: basezindex,
                        left: 0,
                        top: 0,
                        display: "none"
                    })
                    .html(textdn)
                    .appendTo(document.body);
                if (!options.classtextdn)
                    $textdn.css({
                        border: setting.magnifierborder,
                        background: setting.textdnbackground,
                        padding: setting.textdnpadding,
                        font: setting.textdnfont
                    });
                $textdn.css({
                    width: setting.magnifiersize[0] - parseInt($textdn.css("padding-left")) - parseInt($textdn.css("padding-right"))
                });
            }
            $tracker.data("largeimage", setting.largeimage);


            // EVENTS
            $(window).bind("resize", function () {
                var o = $img.offsetsl();
                if ($tracker.data("loadimgevt"))
                    $tracker.css({
                        left: o.left,
                        top: o.top
                    });
                $statusdiv.filter(".preloadevt").css({
                    left: o.left + img.w / 2 - $statusdiv.width() / 2,
                    top: o.top + img.h / 2 - $statusdiv.height() / 2,
                    visibility: 'visible'
                });
            });
            $(document).mousemove(function (e) {
                self.cld.docX = e.pageX;
                if (self.cld.pageX999 !== self.cld.docX) {
                    clearTimeout(self.cld.controlTimer);
                    clearTimeout(self.cld.controlTimer2);
                    $img.css({
                        visibility: "visible"
                    });
                }
            });
            $img.mouseover(function (e) {
                var o = $img.offsetsl();
                $tracker.css({
                    left: o.left,
                    top: o.top
                }).show();
            });
            $tracker.mouseover(function (e) {

                self.cld.pageX999 = e.pageX;
                self.cld.pageY999 = e.pageY;

                cld.pageX999 = e.pageX;
                cld.pageY999 = e.pageY;

                self.cld.docX = e.pageX;

                var o = $img.offsetsl(),
                    pageX = self.cld.pageX999 - o.left,
                    pageY = self.cld.pageY999 - o.top;

                self.cld.destK = pageX;
                self.cld.destL = pageY;

                self.cld.currU = pageX;
                self.cld.currV = pageY;

                self.cld.destM = self.cld.pageX999;
                self.cld.destN = self.cld.pageY999;

                self.cld.destU = self.cld.pageX999 - 10;
                self.cld.destV = self.cld.pageY999 + 20;

                $tracker.css({
                    cursor: setting.magnifycursor
                });
                setting.largeimage = $img.attr("data-large") || $img.attr("src");

                $statusdiv.show();
                clearTimeout(self.cld.controlTimer);
                clearTimeout(self.cld.controlTimer2);

                if (setting.largeimage !== $tracker.data('largeimage')) {

                    $(new Image()).on('load', function () {}).attr("src", setting.largeimage);

                    $($tracker).unbind();
                    $($statusdiv).remove();
                    $($cursorshade).remove();
                    $($magnifier).remove();
                    $($tracker).remove();
                    $($textdn).remove();

                    self.init($img, options, true);
                }
                if ($tracker.data("loadevt")) {
                    $cursorshade.fadeIn();
                    self.showimage($tracker);
                    self.controlLoop($tracker);
                    self.controlLoop2($tracker);
                }

            });
            $tracker.mousemove(function (e) {
                setting.largeimage = $img.attr("data-large") || $img.attr("src");
                if (setting.largeimage !== $tracker.data("largeimage")) {

                    $(new Image()).on('load', function () {}).attr("src", setting.largeimage);

                    $($tracker).unbind();
                    $($statusdiv).remove();
                    $($cursorshade).remove();
                    $($magnifier).remove();
                    $($tracker).remove();
                    $($textdn).remove();

                    self.init($img, options, true);
                }

                self.cld.pageX999 = e.pageX;
                self.cld.pageY999 = e.pageY;

                cld.pageX999 = e.pageX;
                cld.pageY999 = e.pageY;

                self.cld.docX = e.pageX;
            });
            $tracker.mouseout(function (e) {
                clearTimeout(self.cld.controlTimer);
                clearTimeout(self.cld.controlTimer2);
                $img.css({
                    visibility: "visible"
                });
                $textdn.hide();
                $cursorshade.add($statusdiv.not(".preloadevt")).stop(true, true).hide();
            });
            $tracker.one("mouseover", function (e) {

                var imgcoords = $img.offsetsl();
                var $bigimage = $('<img src="' + setting.largeimage + '"/>').css({
                    position: "relative",
                    maxWidth: "none"
                }).appendTo($magnifier);
                if (!self.loaded999[setting.largeimage]) {
                    $tracker.css({
                        opacity: setting.loadopacity,
                        background: setting.loadbackground
                    });
                    $tracker.data("loadimgevt", true);
                    $statusdiv.css({
                        left: imgcoords.left + img.w / 2 - $statusdiv.width() / 2,
                        top: imgcoords.top + img.h / 2 - $statusdiv.height() / 2,
                        visibility: 'visible'
                    });
                }
                $bigimage.bind("loadevt", function (event, e) {

                    if (e.type === "error") return;
                    $tracker.mouseout(function (e) { //image onmouseout
                        self.hideimage($tracker);
                        clearTimeout(self.cld.controlTimer);
                        clearTimeout(self.cld.controlTimer2);
                        $img.css({
                            visibility: "visible"
                        });
                        $textdn.hide();
                        $tracker.hide().css({
                            left: -10000,
                            top: -10000
                        });
                    });
                    $tracker.mouseover(function (e) { //image onmouseover
                        specs.currM = specs.newpower;
                    });
                    $tracker.data("loadimgevt", false);
                    $tracker.css({
                        opacity: 0,
                        cursor: setting.magnifycursor
                    });
                    $statusdiv.empty();
                    if (!options.classstatusdiv)
                        $statusdiv.css({
                            border: setting.statusdivborder,
                            background: setting.statusdivbackground,
                            padding: setting.statusdivpadding,
                            font: setting.statusdivfont,
                            opacity: setting.statusdivopacity
                        });
                    $statusdiv.hide().removeClass("preloadevt");
                    self.loaded999[setting.largeimage] = true;
                    getspecs($bigimage);
                    if (cld.pageX999 == self.cld.docX) {
                        $cursorshade.fadeIn();
                        self.showimage($tracker);
                        clearTimeout(self.cld.controlTimer);
                        clearTimeout(self.cld.controlTimer2);
                        self.controlLoop($tracker);
                        self.controlLoop2($tracker);
                    }

                    var specs = $tracker.data("specs");
                    $bigimage.css({
                        width: setting.zoomstart * specs.bigimage.w * (img.w / specs.bigimage.w),
                        height: setting.zoomstart * specs.bigimage.h * (img.h / specs.bigimage.h)
                    });
                    $tracker.data("loadevt", true);

                    if (setting.zoomrange && setting.zoomrange[1] > setting.zoomrange[0]) { //if zoom range enabled
                        $tracker.bind("mousewheel", function (e, delta) {
                            var zoomdir = delta < 0 ? "out" : "in",
                                power = specs.newpower,
                                newpower = (zoomdir == "in") ? Math.min(power + setting.stepzoom, setting.zoomrange[1]) : Math.max(power - setting.stepzoom, setting.zoomrange[0]);

                            specs.newpower = newpower;
                            specs.delta = delta;
                            e.preventDefault();
                        });

                    } else if (setting.disablewheel) {
                        $tracker.bind("mousewheel", function (e) {
                            e.preventDefault();
                        });
                    }

                }); //end $bigimage onload */

                if (isImageLoaded($bigimage.get(0)))
                    $bigimage.trigger("loadevt", {
                        type: "load"
                    });
                else $bigimage.bind("load error", function (e) {
                    $bigimage.trigger("loadevt", e)
                });
            });
        },
        loaded999: {}
    });
